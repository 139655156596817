export default {
  methods: {
    getUrlForCurrentLanguage(configurationCode) {
      let languageCode = this.$store.getters.language || '';
      languageCode = languageCode.toUpperCase();
      let url = this.$store.getters['configuration/value'](`${configurationCode}.${languageCode}`);
      if (!url) {
        url = this.$store.getters['configuration/value'](configurationCode);
      }
      return url;
    }
  }
};
