<template>
  <div class="home-container">
    <div class="info-container">
      <div v-if="url">
        <iframe
          class="dashboard-iframe"
          width="95%"
          :height="powerBIHeight"
          :src="url"
          frameborder="0"
          allowFullScreen="true"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PowerBI',
  props: {
    url: {
      default: '',
      type: String
    },
    toggleClick: {
      default: null,
      type: Function
    }
  },
  computed: {
    powerBIHeight() {
      return window.innerHeight * 0.8;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.home-container {
  .info-container {
    margin-top: 5px;
    color: #fff;
    background-color: white;
    margin-left: 10px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    .display_name {
      font-size: 20px;
      color: #bbbbbb;
      font-weight: bold;
    }

    .display_organization {
      font-size: 15px;
      margin-left: 2px;
      margin-top: 40px;
    }

    .dashboard-item {
      width: 95%;
      text-align: center;
      margin-bottom: 20px;
    }

    .dashboard-title {
      color: black;
      font-family: Montserrat, Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      font-style: normal;
    }

    .dashboard-subtitle {
      color: #bbbbbb;
      font-family: Montserrat, Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 22px;
      line-height: 35px;
      font-style: normal;
    }

    .separator {
      color: black;
      display: flex;
      align-items: center;
      text-align: center;
    }

    .separator::before,
    .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #bbbbbb;
    }

    .separator:not(:empty)::before {
      margin-right: 1em;
    }

    .separator:not(:empty)::after {
      margin-left: 1em;
    }
    .dashboard-iframe {
      margin-top: 10px;
      border: none;
    }
  }
}
</style>
